import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["options", "optionsTemplate"]

  addOption(event) {
    event.preventDefault()
    const template = this.optionsTemplateTarget.cloneNode(true).content
    this.optionsTarget.appendChild(template)
  }

  removeOption(event) {
    event.preventDefault()
    event.target.closest(".option").remove()
  }
}
