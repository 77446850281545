import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["category", "report", "productArea", "resultsMessage", "messageBody", "searchTerm"]

  search(e) {
    const searchValue = e.target.value.toLowerCase().trim()
    const removeAllHiddenClasses = (element) => element.classList.remove("hidden")
    this.resultsMessageTarget.classList.toggle("hidden", searchValue === "")

    this.reportTargets.forEach((report) => {
      const reportName = report.dataset.value.toLowerCase()
      report.classList.toggle("hidden", !reportName.includes(searchValue))
    })

    this.categoryTargets.forEach((category) => {
      const categoryName = category.dataset.value.toLowerCase()
      const categoryNameInSearch = categoryName.includes(searchValue)

      if (categoryNameInSearch) {
        category.classList.remove("hidden")
        const reports = category.querySelectorAll("li")
        reports.forEach(removeAllHiddenClasses)
      } else {
        category.classList.add("hidden")
      }
      if (!categoryNameInSearch) {
        const allAreHidden = Array.from(category.querySelectorAll("li")).every((report) =>
          report.classList.contains("hidden")
        )
        category.classList.toggle("hidden", allAreHidden)
      }
    })

    this.productAreaTargets.forEach((area) => {
      const productAreaName = area.querySelector("h2").innerText.toLowerCase()
      const productAreaNameInSearch = productAreaName.includes(searchValue)

      if (productAreaNameInSearch) {
        area.classList.remove("hidden")
        const categories = area.querySelectorAll("section.category")
        categories.forEach((category) => {
          category.classList.remove("hidden")
          const reports = category.querySelectorAll("li")
          reports.forEach(removeAllHiddenClasses)
        })
      } else {
        area.classList.add("hidden")
      }
      if (!productAreaNameInSearch) {
        const allAreHidden = Array.from(area.querySelectorAll("section.category")).every((category) =>
          category.classList.contains("hidden")
        )
        area.classList.toggle("hidden", allAreHidden)
      }
    })

    // Get a count of the number of visible menu items and display
    const visibleMenuItemsCount = this.reportTargets.filter((item) => !item.classList.contains("hidden")).length
    this.messageBodyTarget.innerText = `${visibleMenuItemsCount} ${
      // eslint-disable-next-line no-undef
      visibleMenuItemsCount === 1 ? I18n.t("js.settings.show.search.result") : I18n.t("js.settings.show.search.results")
    }`
    this.searchTermTarget.innerText = searchValue
  }
}
