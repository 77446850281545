import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "label"]

  toggle() {
    this.contentTarget.disabled = !this.contentTarget.disabled

    this.labelTarget.setAttribute("aria-disabled", this.contentTarget.disabled)

    // if the content is disabled then input field should be cleared
    if (this.contentTarget.disabled) {
      this.contentTarget.checked = false
    }
  }
}
