/* global google: true */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "streetLineOne",
    "streetLineTwo",
    "city",
    "state",
    "country",
    "postcode",
    "input",
    "latitude",
    "longitude",
  ]

  // We don't do this on `connect` because otherwise the maps SDK adds a placeholder to the input, which we don't want.
  lazilyCreateAutocomplete() {
    if (this.autocomplete) return

    const fields = ["address_components"]
    if (this.hasLatitudeTarget && this.hasLongitudeTarget) {
      fields.push("geometry")
    }
    this.autocomplete = new google.maps.places.Autocomplete(this.inputTarget, { fields })
    this.autocomplete.addListener("place_changed", () => {
      const address_components = this.autocomplete.getPlace().address_components
      const geometry_components = this.autocomplete.getPlace().geometry
      this.rationaliseAddressComponents(address_components, geometry_components)
    })
  }

  rationaliseAddressComponents(components, geometry_components) {
    const address = {}
    components.forEach((component) => {
      const type = component.types[0]
      address[type] = component.long_name
    })
    const street_number = address.street_number ? `${address.street_number} ` : ""

    if (address.country.toLowerCase() === "United States".toLowerCase()) {
      this.streetLineOneTarget.value = street_number.concat(address.route)
      this.streetLineTwoTarget.value = address.subpremise || ""
    } else {
      const unit_number = address.subpremise ? `${address.subpremise}/` : ""
      const parts = [unit_number, street_number, address.route]

      // ref: +Payroll::StpSubmission::Employee::ErrorsAndWarnings+
      // Address lines can't be more than 38 characters for STP.
      if (parts.join("").length <= 38) {
        this.streetLineOneTarget.value = parts.join("")
        this.streetLineTwoTarget.value = ""
      } else if (parts.slice(0, 2).join("").length <= 38 && parts[2].length <= 38) {
        this.streetLineOneTarget.value = parts.slice(0, 2).join("")
        this.streetLineTwoTarget.value = parts[2]
      } else if (parts[0].length <= 38 && parts.slice(1, 3).join("").length <= 38) {
        this.streetLineOneTarget.value = parts[0]
        this.streetLineTwoTarget.value = parts.slice(1, 3).join("")
      } else {
        // give up...
        this.streetLineOneTarget.value = parts.join("").slice(0, 38)
        this.streetLineTwoTarget.value = parts.join("").slice(38, 76)
      }
    }
    if (this.hasLatitudeTarget && this.hasLongitudeTarget) {
      this.latitudeTarget.value = geometry_components.location.lat()
      this.longitudeTarget.value = geometry_components.location.lng()
    }
    this.cityTarget.value = address.locality || address.postal_town || address.political || ""
    this.stateTarget.value = address.administrative_area_level_1 || ""
    this.countryTarget.value = address.country || ""
    this.postcodeTarget.value = address.postal_code || ""

    if (this.streetLineOneTarget.value === "undefined") {
      this.streetLineOneTarget.value = ""
    }
    this.updateInput()
  }

  updateInput() {
    // Same logic as Address#to_human_readable_string

    this.inputTarget.value = [
      this.streetLineOneTarget.value,
      this.streetLineTwoTarget.value,
      this.cityTarget.value,
      this.stateTarget.value,
      this.postcodeTarget.value,
      this.countryTarget.value,
    ]
      .filter((s) => s.trim().length)
      .join(", ")
  }
}
