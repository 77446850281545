import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["enableCheckbox", "monthlyContainer", "notMonthlyContainer"]

  connect() {
    this.update()
  }

  hideMonthly() {
    this.monthlyContainerTarget.classList.add("hidden")
    this.monthlyContainerTarget.disabled = true

    this.notMonthlyContainerTarget.classList.remove("hidden")
    this.notMonthlyContainerTarget.disabled = false
  }

  showMonthly() {
    this.monthlyContainerTarget.classList.remove("hidden")
    this.monthlyContainerTarget.disabled = false

    this.notMonthlyContainerTarget.classList.add("hidden")
    this.notMonthlyContainerTarget.disabled = true
  }

  update() {
    if (this.enableCheckboxTarget.checked) {
      this.showMonthly()
    } else {
      this.hideMonthly()
    }
  }
}
