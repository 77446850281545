/* global I18n, moment */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["description", "interval", "aggressive", "roster"]

  getBaseRosteredTime() {
    return moment().hour(9).minute(0).second(0)
  }

  connect() {
    this.updateText()
  }

  updateText() {
    const dateFormat = "h:mm A"
    const messages = []

    const interval = parseFloat(this.intervalTarget.value) || 0
    const aggressive = this.aggressiveTarget.checked
    const gap = Math.ceil(interval / 3)

    const timeNearRosteredTime = this.getBaseRosteredTime().subtract(gap, "minutes")
    const timeFarFromRosteredTime = this.getBaseRosteredTime().subtract(interval, "minutes").add(gap, "minutes")
    const oneIntervalBeforeRosteredTime = this.getBaseRosteredTime().subtract(interval, "minutes")
    const timeMoreThanOneIntervalBeforeRosteredTime = this.getBaseRosteredTime()
      .subtract(interval, "minutes")
      .subtract(gap, "minutes")
    const timeAfterRosteredStart = this.getBaseRosteredTime().add(gap, "minutes")
    const timeFarAfterRosteredStart = this.getBaseRosteredTime().add(gap + interval, "minutes")
    const intervalAfterRosteredStart = this.getBaseRosteredTime().add(interval, "minutes")

    if (interval === 0) {
      messages.push(I18n.t("js.settings.rounding.clockin_disabled_new"))
    } else if (this.rosterTarget.checked) {
      if (aggressive) {
        messages.push(I18n.t("js.settings.rounding.clockin_not_rostered_aggressive"))
        messages.push("") // line break
      } else {
        messages.push(I18n.t("js.settings.rounding.clockin_not_rostered_minutes", { interval: interval.toString() }))
        messages.push(
          I18n.t("js.settings.rounding.clockin_not_rostered_example_down", {
            time_far_from_rostered_time: timeFarFromRosteredTime.format(dateFormat),
            one_interval_before_rostered_time: oneIntervalBeforeRosteredTime.format(dateFormat),
          })
        )
        messages.push(
          I18n.t("js.settings.rounding.clockin_not_rostered_example_up", {
            time_near_rostered_time: timeNearRosteredTime.format(dateFormat),
            rostered_time: this.getBaseRosteredTime().format(dateFormat),
          })
        )
        messages.push("") // line break
      }
      if (aggressive) {
        messages.push(I18n.t("js.settings.rounding.clockin_rostered_aggressive", { interval: interval }))
      } else {
        messages.push(I18n.t("js.settings.rounding.clockin_rostered_non_aggressive", { interval: interval }))
      }
      messages.push(
        I18n.t("js.settings.rounding.clockin_example", { roster: this.getBaseRosteredTime().format(dateFormat) })
      )
      if (aggressive) {
        messages.push(
          I18n.t("js.settings.rounding.clockin_example_aggressive_rounded", {
            time: timeMoreThanOneIntervalBeforeRosteredTime.format(dateFormat),
            from: timeMoreThanOneIntervalBeforeRosteredTime.format(dateFormat),
          })
        )
      } else {
        messages.push(
          I18n.t("js.settings.rounding.clockin_example_not_aggressive_rounded", {
            time: timeMoreThanOneIntervalBeforeRosteredTime.format(dateFormat),
            to: oneIntervalBeforeRosteredTime.format(dateFormat),
          })
        )
      }
      messages.push(
        I18n.t("js.settings.rounding.clockin_example_rounding_up", {
          time: timeFarFromRosteredTime.format(dateFormat),
          rostered_time: this.getBaseRosteredTime().format(dateFormat),
        })
      )
      messages.push(
        I18n.t("js.settings.rounding.clockin_example_rounding_up", {
          time: timeNearRosteredTime.format(dateFormat),
          rostered_time: this.getBaseRosteredTime().format(dateFormat),
        })
      )
      if (aggressive) {
        messages.push(
          I18n.t("js.settings.rounding.clockin_example_aggressive_rounded", {
            time: timeAfterRosteredStart.format(dateFormat),
            from: timeAfterRosteredStart.format(dateFormat),
          })
        )
      } else {
        messages.push(
          I18n.t("js.settings.rounding.clockin_example_rounding_down", {
            time: timeAfterRosteredStart.format(dateFormat),
            rostered_time: this.getBaseRosteredTime().format(dateFormat),
          })
        )
        messages.push(
          I18n.t("js.settings.rounding.clockin_example_rounding_down", {
            time: timeFarAfterRosteredStart.format(dateFormat),
            rostered_time: intervalAfterRosteredStart.format(dateFormat),
          })
        )
      }
    } else {
      if (aggressive) {
        messages.push(I18n.t("js.settings.rounding.clockin_no_roster_aggressive", { minutes: interval.toString() }))
        messages.push(
          I18n.t("js.settings.rounding.clockin_no_roster_aggressive_example", {
            time: timeFarFromRosteredTime.format(dateFormat),
            to: this.getBaseRosteredTime().format(dateFormat),
          })
        )
      } else {
        messages.push(I18n.t("js.settings.rounding.clockin_no_roster_no_aggressive", { minutes: interval.toString() }))
        messages.push(
          I18n.t("js.settings.rounding.clockin_no_roster_no_aggressive_example", {
            time: timeFarFromRosteredTime.format(dateFormat),
            to: oneIntervalBeforeRosteredTime.format(dateFormat),
          })
        )
      }
      messages.push(
        I18n.t("js.settings.rounding.clockin_no_roster_general_example", {
          time: timeNearRosteredTime.format(dateFormat),
          to: this.getBaseRosteredTime().format(dateFormat),
        })
      )
    }

    // eslint-disable-next-line no-unsanitized/property
    this.descriptionTarget.innerHTML = messages.join("<br>")
  }
}
