/* global I18n */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["description", "interval"]

  static values = {
    warnAboutClockInBreaks: Boolean,
  }

  connect() {
    this.updateText()
  }

  updateText() {
    const messages = []

    const interval = parseFloat(this.intervalTarget.value) || 0
    const shortBreak = String(Math.max(0, 30 - interval))
    const longBreak = String(Math.max(0, 30 + interval))

    if (interval === 0) {
      messages.push(I18n.t("js.settings.rounding.break_rounding_disabled"))
    } else if (this.warnAboutClockInBreaksValue) {
      messages.push(I18n.t("js.settings.rounding.break_rounding_warn_no_clock_in_breaks"))
      messages.push(I18n.t("js.settings.rounding.break_rounding_warn_no_clock_in_breaks_two"))
    } else {
      messages.push(I18n.t("js.settings.rounding.break_rounding_example", { short_break: shortBreak }))
      messages.push(I18n.t("js.settings.rounding.break_rounding_down_example", { long_break: longBreak }))
    }

    // eslint-disable-next-line no-unsanitized/property
    this.descriptionTarget.innerHTML = messages.join("<br><br>")
  }
}
