/* global I18n, moment */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["description", "interval", "aggressive", "roster"]

  getBaseRosteredTime() {
    return moment().hour(17).minute(0).second(0)
  }

  connect() {
    this.updateText()
  }

  updateText() {
    const dateFormat = "h:mm A"
    const messages = []

    const interval = parseFloat(this.intervalTarget.value) || 0
    const aggressive = this.aggressiveTarget.checked
    const gap = Math.ceil(interval / 3)

    const timeNearRosteredTime = this.getBaseRosteredTime().add(gap, "minutes")
    const timeFarFromRosteredTime = this.getBaseRosteredTime().add(interval, "minutes").subtract(gap, "minutes")
    const oneIntervalBeforeRosteredTime = this.getBaseRosteredTime().add(interval, "minutes")
    const timeMoreThanOneIntervalBeforeRosteredTime = this.getBaseRosteredTime()
      .add(interval, "minutes")
      .add(gap, "minutes")
    const timeBeforeRosteredStart = this.getBaseRosteredTime().subtract(gap, "minutes")
    const timeFarBeforeRosteredStart = this.getBaseRosteredTime().subtract(gap + interval, "minutes")
    const intervalBeforeRosteredStart = this.getBaseRosteredTime().subtract(interval, "minutes")

    if (interval === 0) {
      messages.push(I18n.t("js.settings.rounding.clockout_disabled_new"))
    } else if (this.rosterTarget.checked) {
      if (aggressive) {
        messages.push(I18n.t("js.settings.rounding.clockout_not_rostered_aggressive"))
        messages.push("") // line break
      } else {
        messages.push(I18n.t("js.settings.rounding.clockout_not_rostered_minutes", { interval: interval.toString() }))
        messages.push(
          I18n.t("js.settings.rounding.clockout_not_rostered_example_down", {
            time: timeNearRosteredTime.format(dateFormat),
            to: this.getBaseRosteredTime().format(dateFormat),
          })
        )
        messages.push(
          I18n.t("js.settings.rounding.clockout_not_rostered_example_up", {
            time: timeFarFromRosteredTime.format(dateFormat),
            to: oneIntervalBeforeRosteredTime.format(dateFormat),
          })
        )
        messages.push("") // line break
      }
      if (aggressive) {
        messages.push(I18n.t("js.settings.rounding.clockout_rostered_aggressive", { interval: interval }))
      } else {
        messages.push(I18n.t("js.settings.rounding.clockout_rostered_non_aggressive", { interval: interval }))
      }
      messages.push(
        I18n.t("js.settings.rounding.clockout_example", { roster: this.getBaseRosteredTime().format(dateFormat) })
      )
      if (aggressive) {
        messages.push(
          I18n.t("js.settings.rounding.clockout_example_aggressive_rounded", {
            time: timeBeforeRosteredStart.format(dateFormat),
            from: timeBeforeRosteredStart.format(dateFormat),
          })
        )
      } else {
        messages.push(
          I18n.t("js.settings.rounding.clockout_example_not_aggressive_rounded", {
            time: timeFarBeforeRosteredStart.format(dateFormat),
            to: intervalBeforeRosteredStart.format(dateFormat),
          })
        )
        messages.push(
          I18n.t("js.settings.rounding.clockout_example_not_aggressive_rounded", {
            time: timeBeforeRosteredStart.format(dateFormat),
            to: this.getBaseRosteredTime().format(dateFormat),
          })
        )
      }
      messages.push(
        I18n.t("js.settings.rounding.clockout_example_rounding_down", {
          time: timeNearRosteredTime.format(dateFormat),
          rostered_time: this.getBaseRosteredTime().format(dateFormat),
        })
      )
      messages.push(
        I18n.t("js.settings.rounding.clockout_example_rounding_down", {
          time: timeFarFromRosteredTime.format(dateFormat),
          rostered_time: this.getBaseRosteredTime().format(dateFormat),
        })
      )
      if (aggressive) {
        messages.push(
          I18n.t("js.settings.rounding.clockout_example_aggressive_far", {
            time: timeMoreThanOneIntervalBeforeRosteredTime.format(dateFormat),
          })
        )
      } else {
        messages.push(
          I18n.t("js.settings.rounding.clockout_example_rounding_down", {
            time: timeMoreThanOneIntervalBeforeRosteredTime.format(dateFormat),
            rostered_time: oneIntervalBeforeRosteredTime.format(dateFormat),
          })
        )
      }
    } else {
      if (aggressive) {
        messages.push(I18n.t("js.settings.rounding.clockout_no_roster_aggressive", { minutes: interval.toString() }))
      } else {
        messages.push(I18n.t("js.settings.rounding.clockout_no_roster_no_aggressive", { minutes: interval.toString() }))
      }
      messages.push(
        I18n.t("js.settings.rounding.clockout_no_roster_general_example", {
          time: timeNearRosteredTime.format(dateFormat),
          to: this.getBaseRosteredTime().format(dateFormat),
        })
      )
      if (aggressive) {
        messages.push(
          I18n.t("js.settings.rounding.clockout_no_roster_aggressive_example", {
            time: timeFarFromRosteredTime.format(dateFormat),
            to: this.getBaseRosteredTime().format(dateFormat),
          })
        )
      } else {
        messages.push(
          I18n.t("js.settings.rounding.clockout_no_roster_no_aggressive_example", {
            time: timeFarFromRosteredTime.format(dateFormat),
            to: oneIntervalBeforeRosteredTime.format(dateFormat),
          })
        )
      }
    }

    // eslint-disable-next-line no-unsanitized/property
    this.descriptionTarget.innerHTML = messages.join("<br>")
  }
}
